import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { getQueryVariable } from '../util/api';

export default function Solutions() {
  const type = getQueryVariable('type');
  const [data, setData] = useState<any>('');
  useEffect(() => {
    setData(type)
  },[])

  return (
    <Layout nav="solutions">
      <SEO title="解决方案" />
      <section className="py-4" style={{ backgroundColor: '#293D5D' }}>
        <div className="container mx-auto px-4 sm:px-16 flex items-center justify-between">
          <div>
            <h1 className="text-3xl sm:text-4xl text-white">解决方案</h1>
            <h2 className="text-xs text-white opacity-60">QingPai Solutions</h2>
          </div>
          <img src={require('../assets/87d288.png')} alt="图片" className="h-40" />
        </div>
      </section>
      {(!data || data == 'monitor') && (
        <section className="bg-white py-8 min-h-screen">
          <div className="container mx-auto px-4">
            <img src={require('../assets/a695a5.png')} alt="长图" className="w-full" />
          </div>
        </section>
      )}

      {data == 'crm' && (
        <section className="bg-white py-8 min-h-screen">
          <div className="container mx-auto px-4">
            <img src={require('../assets/crm1.jpg')} alt="图片" className="w-full" />
          </div>
          <div className="container mx-auto px-4">
            <img src={require('../assets/crm2.jpg')} alt="图片" className="w-full" />
          </div>
          <div className="container mx-auto px-4">
            <img src={require('../assets/crm3.jpg')} alt="图片" className="w-full" />
          </div>
          <div className="container mx-auto px-4">
            <img src={require('../assets/crm4.jpg')} alt="图片" className="w-full" />
          </div>
          <div className="container mx-auto px-4">
            <img src={require('../assets/crm5.jpg')} alt="图片" className="w-full" />
          </div>
          <div className="container mx-auto px-4">
            <img src={require('../assets/crm6.jpg')} alt="图片" className="w-full" />
          </div>
          <div className="container mx-auto px-4">
            <img src={require('../assets/crm7.jpg')} alt="图片" className="w-full" />
          </div>
        </section>
      )}

    </Layout>
  );
}
